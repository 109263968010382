import { LocaleMessageObject } from 'vue-i18n';
import { FORM_ERRORS } from '@/components/registration/settings/rules';
import { GENDER, PROFILE_ADDITIONAL_INFO_KEYS } from '@/api/models/client';
import { AGREEMENT_DOCUMENT_TYPE } from '@/views/profile/Documents/type';
import { applyMask, mobilePhoneMask } from '@/components/registration/settings/mask';
import { FileMaxSize } from '@/utils/file';
import { PROFILE_ROUTES } from '@/router/routes_type';
import { GRID_ROW_TYPE } from '@/components/text/types';
import { DISBURSEMENT_TYPE } from '@/api/models/disbursement';
import { OTHER_DOCUMENT_TYPES } from '@/api/models/document';
import { DebtSettlementApplicationStatus } from '@/api/models/debtSettlementApplication';

const phoneNumber = applyMask('+77080750888', mobilePhoneMask);

export const locale: LocaleMessageObject = {
  LANGUAGES: {
    RU: 'RU',
    KK: 'KZ',
    OPTIONS: {
      RU: 'Русский',
      KK: 'Казахский'
    }
  },
  COMMON: {
    BACK_BUTTON: 'Назад'
  },
  PAYMENT_GATEWAY: {
    TRANSACTION: {
      SUCCESS: 'Оплата успешно проведена',
      ERROR: 'Не удалось провести оплату'
    }
  },
  NOTIFICATION: {
    PASSWORD_CHANGED: {
      TITLE: 'Пароль успешно изменен',
      MESSAGE: 'Теперь Вы можете войти в систему с новым паролем'
    },
    PASSWORD_RESET: {
      NEW_CODE_SENT: 'Сообщение с кодом отправлено'
    },
    EMAIL_CHANGE: {
      NEW_CODE_SENT: 'Новый код отправлен на email',
      SUCCESS: 'Email успешно изменен',
      ERROR: 'Ошибка при смене email'
    },
    CALCULATOR: {
      ERROR: 'Ошибка при подаче заявки'
    },
    APPROVE_OFFER: {
      ERROR: 'Не удалось подтвердить предложение'
    },
    PHONE_CHANGE: {
      NEW_CODE_SENT: 'Новый SMS-код отправлен',
      SUCCESS: 'Телефон успешно изменен',
      ERROR: 'Ошибка при смене телефона'
    },
    DOCUMENT_UPLOAD: {
      ERROR: 'Ошибка при загрузке'
    },
    SMS_CODE: {
      TITLE: 'Новый SMS-код отправлен'
    },
    IMAGE_UPLOAD: {
      INCORRECT_IMAGE_FILE_FORMAT: 'Изображение имеет неверный формат. Формат должен быть jpeg или png',
      INCORRECT_IMAGE_FILE_SIZE: `Размер исходного изображения не соответствует необходимому формату, загрузите пожалуйста фото размером не более ${FileMaxSize.IMAGE} МБ.`
    },
    CARD_UNILNK: {
      SUCCESS: 'Проверка карты отменена',
      ERROR: 'Ошибка при отмене проверки карты'
    }
  },
  MENU: {
    CREDIT_FOR: 'Микрокредит для',
    HOW_TO_RETURN: 'Как возвращать',
    FAQ: 'Частые вопросы',
    NEWS: 'Новости',
    CONTACTS: 'Контакты',
    REPORTS: 'Отчеты',
    DOCUMENTS: 'Документы',
    PHONE_NR: phoneNumber,
    AUTH: 'Войти',
    PROFILE: 'Мой профиль',
    LOGOUT: 'Выйти'
  },
  LAYOUT_SECTIONS: {
    SECTION_MAIN: {
      LINE_1: 'Микрокредит',
      LINE_2: 'По низкой ставке на любые цели',
      LINE_3: 'Salem.kz может не только спасти в ситуациях когда срочно нужны деньги, но и помочь заработать!',
      BUTTON: 'Получить Микрокредит',
      LINE_4: 'О нас'
    },
    SECTION_ANY_GOALS: {
      LINE_1: 'Онлайн Микрокредит на любые цели',
      LINE_2:
        'Salem.kz поможет вам рефинансировать кредит в другом банке, отремонтировать или купить недвижимость, автомобиль, оплатить медицинские или образовательные услуги, организовать мероприятие и многое другое.',
      CARD_1: 'Рефинансирование',
      CARD_2: 'Для бизнеса',
      CARD_3: 'Медицинские услуги',
      CARD_4: 'Ремонт и строительство',
      CARD_5: 'Ремонт автомобиля'
    },
    SECTION_BUSINESS: {
      LINE_1: 'Микрокредит для малого бизнеса',
      LINE_2: 'Заполните короткую анкету онлайн и получите мгновенное решение на сумму до 3 000 000 тенге! ',
      BUTTON: 'Получить Микрокредит'
    },
    SECTION_FAQ: {
      LINE_1: 'Часто задаваемые вопросы'
    },
    SECTION_WHY_SALEM: {
      TITLE: 'Почему именно Salem.kz?',
      SECTIONS: {
        ONLINE: {
          TITLE: 'Весь процесс <br>полностью онлайн',
          CONTENT:
            '<b>С нами удобно!</b> Salem.kz  бережно относится к самому дорогому ресурсу нашей жизни – времени. Мы экономим ваше время, помогая получить микрокредит онлайн быстро и на выгодных для вас условиях!'
        },
        PERCENTAGE: {
          TITLE: 'Низкие <br>процентные ставки',
          CONTENT:
            '<b>С нами выгодно!</b> Мы выдаем крупные суммы на длительный срок, под одну из САМЫХ низких процентных ставок на рынке – на уровне банковских продуктов. Заполните короткую анкету на сайте Salem.kz и получите все преимущества нашего онлайн-продукта!'
        },
        SAFE: {
          TITLE: 'Гарантия <br>безопасности',
          CONTENT:
            '<b>С нами надежно!</b> Salem.kz – полностью прозрачная компания, деятельность которой регулируется Национальным банком Республики Казахстан. Оформить микрокредит онлайн срочно на сайте Salem.kz можно из любой точки Казахстана. С Salem.kz вы не зависите от графика работы банков и их локации. У нас, как в банке, только лучше!'
        }
      }
    },
    SECTION_8: {
      LINE_1: 'Награды и рейтинги',
      LINE_2:
        'Мы — полностью прозрачная компания, наша деятельность регулируется Национальным Банком Республики Казахстан.',
      LINE_3:
        'Вы можете подать заявку на кредит, даже если у вас с собой только смартфон и ИИН.Вся анкета заполняется онлайн не более чем за 8 минут, решение принимается мгновенно.Выдача производится на зарегистрированные на имя клиента карту или банковский счет.',
      LINE_4:
        'Вы можете подать заявку на кредит, даже если у вас с собой только смартфон и ИИН.Вся анкета заполняется онлайн не более чем за 8 минут, решение принимается мгновенно.',
      LINE_5: 'Выдача производится на зарегистрированные на имя клиента карту или банковский счет.'
    },
    SECTION_QUESTIONNAIRE: {
      TITLE: 'Заполните анкету',
      SUBTITLE: 'и получите мгновенное решение!',
      BUTTON: 'Получить Микрокредит'
    },
    SECTION_ABOUT: {
      LINE_1: 'О проекте Salem.kz',
      LINE_2:
        'МФО «Салем Кредит» – надежная, клиентоориентированная компания. Мы являемся экспертами в кредитном скоринге и финансовых технологиях благодаря команде признанных профессионалов финтех-индустрии, работающих у нас. ',
      LINE_3: 'Как мы выдаем кредиты',
      LINE_4:
        'Мы используем новейшие технологии для обеспечения мгновенной идентификации и оценки потребителей, что позволяет нам максимально быстро удовлетворять запросы клиентов. Вы можете подать заявку на микрокредит онлайн, имея на руках лишь смартфон и ИИН. Само оформление заявки займет считанные минуты, а полностью автоматизированный процесс оценки платежеспособности клиента позволит системе принять решение практически мгновенно. Микрокредит можно получить не только быстро, но, даже не выходя из дома или не покидая офиса, территориальное положение тоже не играет ни какой роли. Деньги поступят на зарегистрированные на имя клиента карту или банковский счет. ',
      LINE_5: 'Международная компания',
      LINE_6:
        'Проект Salem.kz входит в состав финансовой группы SunFinance – самого быстрорастущего международного холдинга в сфере онлайн-кредитования в Европе, филиалы которого расположены в Латвии, Эстонии, Польше, Дании, Чешской Республике, Мексике, Швеции, Киргизии и во Вьетнаме. Возглавляет Sun Finance команда профессионалов, более 10 лет работающих на рынке потребительского кредитования и сочетающих в своей деятельности применение передовых разработок в системе принятия решений о выдаче кредита с принципами ответственного кредитования. ',
      LINE_7: 'Что такое кредит онлайн в Казахстане? ',
      LINE_8:
        'Кредит-онлайн – это денежные средства в размере до 3 000 000 тенге, которые могут быть выданы на срок до 3 лет на любые цели. Кредит погашается путем ежемесячных аннуитетных (т. е.  распределенных равными суммами) платежей. \n' +
        '\n' +
        'Кредиты-онлайн экономят самый дорогой ресурс современной жизни – время! Забудьте о сборе документов и утомительных очередях. Заявка на кредит оформляется онлайн из любой точки Казахстана, для этого необходимы лишь доступ в интернет, любое устройство: мобильный, планшет или ноутбук и несколько минут вашего времени. Деньги отправят на банковский счет или карту в течение 15 минут после одобрения заявки. Микрокредит на карту можно получить в день обращения. ',
      LINE_9: 'Наши условия',
      LINE_10:
        'Условия кредитования физических лиц и ставки максимально приближены к банковским продуктам. Потребительские кредиты размером до 3 000 000 тенге выдаются без залога на срок до 3 лет, процентная ставка полностью соответствует требованиям Национального банка Республики Казахстан. \n' +
        'Принятие решений, основанных на Big Data, и использование продвинутых моделей кредитного скоринга обеспечивают быстроту и точность всех процессов. \n' +
        'При создании нашей системы мы рассмотрели ошибки и недостатки других систем рынка онлайн-кредитования, что позволило создать собственную уникальную систему кредитного конвейера, работающую значительно эффективнее. '
    }
  },
  FOOTER: {
    LINK_1: 'Для бизнеса',
    LINK_2: 'Карта сайта',
    LINK_3: 'Частые вопросы',
    LINK_4: 'Отзывы',
    LINK_5: 'Документы',
    LINK_6: 'Контакты',
    LINK_NEWS: 'Новости',
    ADDRESS:
      '<strong>Юридический адрес:</strong> 050012, Республика Казахстан, г. Алматы, Алмалинский район, проспект Сейфуллина, дом №502, 8 этаж',
    PHONE: `<strong>Телефон:</strong> ${phoneNumber}`,
    PARTNERS: 'Sun finance в мире',
    COPYRIGHTS: '2020 Copyrights - Salem.kz - All Rights Reserved.',
    LICENSE:
      'ТОО "Микрофинансовая организация "Салем Кредит" лицензия на осуществление микрофинансовой деятельности №02.21.0060.М от 31.03.2021. ГЭСВ (min ставка вознаграждения 19.9%, max 56% годовых)'
  },
  NEWS: {
    TITLE: 'Новости'
  },
  LOGIN: {
    TITLE: 'Войти в профиль',
    FORGOT_PASSWORD: 'Забыли пароль?',
    ENTER: 'Войти',
    EMAIL_OR_INN: 'Ваш ИИН',
    PASSWORD: 'Ваш пароль'
  },
  FORGOT_PASSWORD: {
    TITLE: 'Забыли пароль?',
    SUBTITLE: 'Восстановите и получите новый',
    METHODS: {
      EMAIL: {
        TITLE: 'ПО ЭЛ. ПОЧТЕ',
        SUBTITLE: 'Мы отправим Вам код для восстановления пароля на e-mail адрес'
      },
      PHONE: {
        TITLE: 'ПО SMS',
        SUBTITLE: 'Мы отправим Вам код для восстановления пароля на телефон через SMS'
      }
    },
    METHOD_FORM: {
      SUBMIT: 'Отправить',
      OR_METHOD: 'ИЛИ',
      CONTACT_SUPPORT: 'Если забыли почту, обратитесь в службу поддержки по номеру на сайте',
      ANOTHER_METHOD: '{link} способ восстановления'
    },
    EMAIL_METHOD: {
      TITLE: 'Для восстановления пароля, введите e-mail адрес, указанный при регистрации',
      PHONE_METHOD: 'SMS'
    },
    PHONE_METHOD: {
      TITLE: 'Для восстановления пароля, введите номер мобильного телефона, указанный при регистрации ',
      EMAIL_METHOD: 'Эл. почта'
    },
    VERIFICATION: {
      TITLE: 'Сброс пароля отправлен',
      SUBMIT: 'Отправить',
      CODE_NOT_RECEIVED: 'Не получили код подтверждения?',
      SEND_CODE_AGAIN: 'Отправить код повторно',
      EMAIL: {
        TITLE:
          'На указанный e-mail был выслан код подтверждения.<br> Пожалуйста, введите данный код в предоставленное поле.',
        NEW_CODE_SENT: 'Новый код отправлен на email'
      },
      PHONE: {
        TITLE:
          'На указанный номер телефона был выслан код подтверждения.<br> Пожалуйста, введите данный код в предоставленное поле.',
        NEW_CODE_SENT: 'Новый SMS-код отправлен'
      }
    }
  },
  RESET_PASSWORD: {
    TITLE: 'Введите новый пароль',
    TITLE_SAVED: 'Ваш пароль успешно изменён',
    SUBMIT: 'Сохранить',
    LOGIN: 'Войти'
  },
  REGISTRATION: {
    TITLE: 'Новый клиент',
    REGISTER: 'Регистрация',
    INFO_1:
      'Если вы ранее пользовались услугами Salem.kz, то вам нужно зарегистрироваться и подать заявку на нашем сайте',
    INFO_2: 'Для новых клиентов доступно до 3 000 000 тенге за 10* минут!',
    CAPTION: '*в случае подписания с ЭЦП'
  },
  REGISTRATION_LOADING: {
    TITLE: 'Информация обрабатывается',
    SUB_TITLE: 'Пожалуйста, подождите несколько секунд'
  },
  REGISTRATION_INCOME: {
    TITLE: 'Обновите информацию о доходах'
  },
  REGISTRATION_PERSONAL_DATA: {
    TITLE: 'Создайте личный кабинет',
    RULE: 'Все поля должны быть заполнены',
    POPUP_TITLE: 'Проверка телефона',
    POPUP_RULE:
      'Нам необходимо убедиться что это ваш номер телефона. На вами указанный номер {hiddenPhoneNumber}, было отправлено смс с кодом.',
    POPUP_SMS_CODE_SEND_AGAIN: 'Отправить СМС-код заново',
    POPUP_SMS_CODE_NOT_SEND: 'Не получили код на телефон?',
    POPUP_CONSENTS_ALL_CHECKED: 'Я ознакомлен и согласен с вышеприложенными согласиями и обязательствами'
  },
  REGISTRATION_FINANCES: {
    BANK_STATEMENT: 'Выписка:',
    ATTENTION: 'Обращаем внимание выписка должна включать в себя информацию <b>за последние 6 месяцев</b>',
    UPLOAD_TITLE: 'Для продолжения необходимо загрузить хотя бы один из файлов:',
    UPLOAD_INFO:
      'Выписка по депозиту (KASPI/HALYK/ЖССБ И ДРУГИЕ) или выписка по счету вашей платежной карты любого банка за последние <b>6 месяцев</b>'
  },
  REGISTRATION_TAKE_LOAN: {
    TITLE: 'Выберите сумму'
  },
  REGISTRATION_DOCUMENTS: {
    TITLE: 'Загрузите изображение удостоверения личности и селфи с этим документом',
    HELPER: 'Как загружать фото?',
    IDENTITY: {
      TITLE: 'Изображение удостоверения личности и селфи',
      FRONT: 'Лицевая часть документа',
      BACK: 'Задняя часть документа',
      SELFIE: 'Селфи с этим документом'
    },
    MODAL: {
      TITLE: 'Как загружать фотографии?'
    },
    MODAL_WITH_SELFIE: {
      CONTENT: [
        {
          TYPE: GRID_ROW_TYPE.IMAGES,
          ROW: [
            {
              img: '<img src="/img/helper/acceptable.png" width="64" height="65" alt="Подходящий формат" /> ',
              legend: 'Подходящий формат'
            },
            {
              img: '<img src="/img/helper/boards-visible.png" width="64" height="64" alt="Видимые края" /> ',
              legend: 'Видимые края'
            },
            {
              img: '<img src="/img/helper/not-blurry.png" width="64" height="64" alt="Не размытое" /> ',
              legend: 'Не размытое'
            },
            {
              img: '<img src="/img/helper/no-flash.png" width="64" height="64" alt="Не отсвечивает" /> ',
              legend: 'Не отсвечивает'
            }
          ]
        },
        {
          ROW: 'Загрузить фотографию удостоверения личности с лицевой и обратной стороны.'
        },
        {
          ROW: 'Загрузите селфи-фотографию, где будет отчётливо видно ваше лицо.'
        },
        {
          ROW:
            'В соответствии с Постановлением Правления Агентства Республики Казахстан по регулированию и ' +
            'развитию финансового рынка № 63 от 30 апреля 2021 года, в соответствии с которым введена обязательная ' +
            'биометрическая идентификация личности заемщика, просим Вас пройти идентификацию с загрузкой фотографий ' +
            'документов, удостоверяющих личность, селфи.'
        },
        {
          ROW:
            'Мы относимся к Вашей конфиденциальности чрезвычайно серьезно. Ваша персональная информация будет ' +
            'храниться в строгой безопасности.'
        }
      ]
    },
    MODAL_WITHOUT_SELFIE: {
      TITLE: 'Как загружать фотографии?',
      CONTENT: [
        {
          TYPE: GRID_ROW_TYPE.IMAGES,
          ROW: [
            {
              img: '<img src="/img/helper/acceptable.png" width="64" height="65" alt="Подходящий формат" /> ',
              legend: 'Подходящий формат'
            },
            {
              img: '<img src="/img/helper/boards-visible.png" width="64" height="64" alt="Видимые края" /> ',
              legend: 'Видимые края'
            },
            {
              img: '<img src="/img/helper/not-blurry.png" width="64" height="64" alt="Не размытое" /> ',
              legend: 'Не размытое'
            },
            {
              img: '<img src="/img/helper/no-flash.png" width="64" height="64" alt="Не отсвечивает" /> ',
              legend: 'Не отсвечивает'
            }
          ]
        },
        {
          ROW: 'Загрузить фотографию удостоверения личности с лицевой и обратной стороны.'
        },
        {
          ROW:
            'В соответствии с Постановлением Правления Агентства Республики Казахстан по регулированию и ' +
            'развитию финансового рынка № 63 от 30 апреля 2021 года, в соответствии с которым введена обязательная ' +
            'биометрическая идентификация личности заемщика, просим Вас пройти идентификацию с загрузкой фотографий ' +
            'документов, удостоверяющих личность.'
        },
        {
          ROW:
            'Мы относимся к Вашей конфиденциальности чрезвычайно серьезно. Ваша персональная информация будет ' +
            'храниться в строгой безопасности.'
        }
      ]
    }
  },
  REGISTRATION_DISBURSEMENT_TYPE: {
    TITLE: 'Выберите способ подписания и зачисления',
    CHOSEN_AMOUNT: 'Сумма к выдаче',
    CHOSEN_TERM: 'Срок микрокредита',
    CHOSEN_INSURANCE: 'Страховка микрокредита'
  },
  MULTI_LOAN_DISBURSEMENT_TYPE: {
    TITLE: 'Обновите способ перечисления'
  },
  REGISTRATION_SUMMARY: {
    TITLE: 'Вы выбрали'
  },
  REGISTRATION_DECISION: {
    TITLE: 'Благодарим за заявку!',
    SUB_TITLE: 'Ваша заявка в обработке.'
  },
  REGISTRATION_OFFER: {
    INCREASE_TITLE: 'Ваш кредитный лимит увеличен!',
    INCREASE_SUBTITLE: 'Вы можете получить больше',
    DECREASE_TITLE: 'Мы можем выдать вам сумму - {amount} тенге!',
    CHOOSE_TEXT: 'Пожалуйста, подтвердите сумму',
    CONFIRM_BASE_BUTTON: 'Получить деньги',
    CONFIRM_INCREASE_BUTTON: 'Получить больше денег'
  },
  REGISTRATION_OFFER_INCREASE: {
    TITLE: 'Поздравляем!',
    SUBTITLE: 'Оценив вашу кредитную историю мы можем предложить вам сумму до <b>{amount}</b>'
  },
  REGISTRATION_MANUAL_VERIFICATION: {
    TITLE: 'Благодарим за заявку!',
    SUB_TITLE: 'В течение 30 минут с Вами свяжется наш сотрудник.'
  },
  REGISTRATION_APPLICATION_APPROVED: {
    TITLE: 'Поздравляем, ваша заявка одобрена!',
    SUBTITLE: 'Деньги поступят после подписания договора.'
  },
  REGISTRATION_APPLICATION_REJECTED: {
    TITLE: 'К сожалению, вам отказано в микрокредите. Вы можете отправьте заявку на микрокредит нашим партнерам.',
    CONTINUE: 'Перейти к партнеру'
  },
  REGISTRATION_VERIGRAM_INTRO: {
    TITLE: 'Биометрия',
    SUB_TITLE: 'Для прохождения биометрии разрешите доступ к камере',
    CONTINUE: 'Начать'
  },
  PROFILE: {
    SETTINGS: 'Настройки профиля',
    NAVIGATION: {
      [PROFILE_ROUTES.MY_LOAN_PAGE]: 'Мои кредиты',
      [PROFILE_ROUTES.DOCUMENTS_PAGE]: 'Документы',
      [PROFILE_ROUTES.CHANGE_PASSWORD_PAGE]: 'Сменить пароль',
      [PROFILE_ROUTES.CHANGE_EMAIL_PAGE]: 'Сменить email',
      [PROFILE_ROUTES.CHANGE_PHONE_PAGE]: 'Сменить телефон',
      [PROFILE_ROUTES.DEBT_SETTLEMENT_LIST]: 'Урегулирование задолженности',
      CURRENT_CREDIT: 'Текущий микрокредит',
      COMMUNICATIONS: 'Мои коммуникации',
      PAYMENTS: 'Мои платежи',
      CONTACT_US: 'Связаться с нами',
      REFER_FRIEND: 'Refer a friend'
    },
    LOGOUT: 'Выйти'
  },
  PROFILE_NO_LOAN: {
    JOURNEY_NOT_FINISHED: 'Для получения микрокредита Вам необходимо завершить и подать заявку.',
    CONTINUE: 'Продолжить'
  },
  PROFILE_MY_LOAN: {
    TITLE: 'Мои кредиты',
    NEW_LOAN: 'Новый микрокредит',
    ADDITIONAL_LOAN_AVAILABLE: 'У Вас имеется дополнительная доступная сумма',
    ADDITIONAL_LOAN_AVAILABLE_BUTTON: 'Получить',
    AGREEMENT_NUMBER: 'Номер договора',
    PRINCIPAL_AMOUNT: 'Сумма выдачи',
    ISSUED_DATE: 'Дата выдачи',
    TERM: 'Срок микрокредита',
    TERM_SUFFIX: 'месяцев',
    TOTAL_TO_PAY_AMOUNT: 'Общая сумма погашения',
    MONTHLY_PAYMENT: 'Ежемесячный платеж',
    PAYMENT_DATE: 'Дата платежа',
    PAYMENTS_GRAPH: 'График платежей',
    PAYMENTS_GRAPH_SHOW: 'Смотреть',
    REPAY_AMOUNT: 'Сумма погашения',
    DEBT_SETTLEMENT: 'Урегулирование задолженности'
  },
  DEBT_SETTLEMENT: {
    TITLE: 'Урегулирование задолженности',
    DOCUMENTS_TITLE: 'Подтверждающие документы',
    DOCUMENTS_TOOLTIP: 'Для подтверждения своей ситуации или статуса СУСН (прикрепите подтверждающие документы)',
    APPLICATION_LIST: 'Урегулирование задолженности по договору {agreementNumber} ({status}):',
    DOCUMENTS: 'Документы',
    ADD_DOCUMENTS: 'Добавить документы',
    APPEAL_TEXT: 'Содержание заявления',
    APPLICATION_SUBMITTED_MESSAGE: 'Ваше обращение принято в работу, просим ожидать ответа.',
    STATUS_TITLE: 'Состояние заявки',
    SUBMIT_APPLICATION: 'Направить обращение',
    DOCUMENTS_PLACEHOLDER: '+ Загрузить документы',
    STATUS: {
      [DebtSettlementApplicationStatus.ACCEPTED]: 'Заявка одобрена',
      [DebtSettlementApplicationStatus.REJECTED]: 'Заявка отклонена',
      [DebtSettlementApplicationStatus.CANCELLED]: 'Заявка прекращена',
      [DebtSettlementApplicationStatus.SUBMITTED]: 'Заявка принята в работу',
      [DebtSettlementApplicationStatus.FIRST_REVIEWED]: 'Заявка на рассмотрении',
      [DebtSettlementApplicationStatus.SECOND_REVIEWED]: 'Заявка на рассмотрении',
      [DebtSettlementApplicationStatus.FIRST_OFFERED]: 'Ожидание ответа на предложение',
      [DebtSettlementApplicationStatus.FIRST_OFFER_EXPIRED]: 'Ожидание ответа на предложение',
      [DebtSettlementApplicationStatus.SECOND_OFFERED]: 'Ожидание ответа на предложение',
      [DebtSettlementApplicationStatus.SECOND_OFFER_EXPIRED]: 'Ожидание ответа на предложение',
      [DebtSettlementApplicationStatus.DOCUMENTS_REQUESTED]: 'Необходимы дополнительные документы'
    }
  },
  PAYMENT_CONFIRM: {
    TITLE: 'Подтвердите оплату',
    TEXT: 'С вашей карты будут сняты <b>{paymentAmount}</b> *. Вы подтверждаете оплату?',
    BUTTON: 'Подтвердить',
    COMMISSION_POSTSCRIPT: '+ комиссия за перевод'
  },
  PROFILE_INFO: {
    FIRST_NAME: 'Имя',
    LAST_NAME: 'Фамилия',
    PHONE: 'Телефон',
    EMAIL: 'Email',
    DECLARED_ADDRESS: 'Адрес регистрации',
    BANK_ACCOUNT: 'Банковский счёт (IBAN)',
    WORK_INFO: 'Занятость',
    CARD: 'Банковская карта'
  },
  PROFILE_MY_DOCUMENTS: {
    TITLE: 'Документы',
    AGREEMENT_DOCUMENT_LIST: 'Договор {agreementNumber}:',
    DEBT_SETTLEMENT_APPLICATION_LIST: 'Урегулирование задолженности по договору {agreementNumber}:',
    CLIENT_DOCUMENT_LIST: 'Другие:',
    DOCUMENTS: {
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT]: 'Договор микрокредита (стандартный)',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_POSTPONEMENT_CONTRACT]: 'Договор об отсрочке',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_POSTPONEMENT_CONTRACT_SIGNATURE]: 'С подписью. Договор об отсрочке',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT]: 'Договор микрокредита',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE]: 'Подписант',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE_INFO]: 'Информация о подписях документа',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_SIGNATURE]: 'С подписью. Договор микрокредита',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT]: 'Договор микрокредита',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE]: 'Подписант',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE_INFO]:
        'Информация о подписях документа',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_SIGNATURE]: 'С подписью. Договор микрокредита',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PERSONAL_DATA]: 'Согласие об обработке персональных данных',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_REPORT]: 'Согласие на выдачу кредитного отчета',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_BUREAU_SHARE]:
        'Согласие на предоставление информации в кредитные бюро',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PROCESS_INCOME]: 'Согласие на получение информации о доходах',
      [AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE]: 'Анкета клиента',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_CONTRACT]: 'Договор микрокредита (полный)',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_APPLICATION]: 'Заявление на страхование',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION]: 'Заявление на перевод суммы',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_SIGNATURE]: 'С подписью. Договор микрокредита (стандартный)',
      [AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE_SIGNATURE]: 'С подписью. Анкета клиента',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_CONTRACT_SIGNATURE]: 'С подписью. Договор микрокредита (полный)',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_APPLICATION_SIGNATURE]: 'С подписью. Заявление на страхование',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION_SIGNATURE]: 'С подписью. Заявление на перевод суммы',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_AMENDMENT]: 'Обновлённый договор',
      [OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT]: 'Приложение к заявке урегулирования задолженности',
      [OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT_OFFER]: 'Предложение по урегулированию задолженности'
    }
  },
  PASSWORD_SETTINGS: {
    TITLE: 'Смена пароля'
  },
  EMAIL_SETTINGS: {
    TITLE: 'Сменить email',
    POPUP_TITLE: 'Проверка email',
    POPUP_RULE: 'Для подтверждения Вашего e-mail на указанный электронный адрес был направлен код',
    POPUP_SMS_CODE_SEND_AGAIN: 'Отправить письмо с кодом повторно'
  },
  PHONE_SETTINGS: {
    TITLE: 'Сменить телефон',
    POPUP_TITLE: 'Проверка телефона',
    POPUP_RULE:
      'Нам необходимо убедиться что это ваш номер телефона. На вами указанный номер {hiddenPhoneNumber}, было отправлено смс с кодом.',
    POPUP_SMS_CODE_SEND_AGAIN: 'Отправить СМС-код заново'
  },
  CONSENTS: {
    PERSONAL_DATA_PROCESSING: 'Согласие на обработку персональных данных',
    CREDIT_REPORT: 'Согласие на выдачу кредитного отчета',
    MICRO_CREDIT_INFO: 'Согласие на предоставление информации о микрокредите в кредитные бюро',
    INCOME_OUTCOME_PROCESSING: 'Согласие на получение и обработку данных о доходах',
    NOT_FOREIGN_GOVERNMENT_OFFICIAL: 'Я подтверждаю, что не являюсь иностранным публичным должностным лицом'
  },
  CREDIT_FOR_BUSINESS: {
    TITLE: 'Микрокредит в Казахстане для',
    SUB_TITLE: 'малого бизнеса',
    TEXT_1:
      'Привлечение заемных средств позволяет владельцам малого бизнеса развивать свое детище, своевременно реагируя на малейшие экономические изменения. Зачастую именно малые предприятия наиболее остро нуждаются в дополнительных оборотных средствах. В то же время банки неохотно дают им кредиты на развитие бизнеса, да и стоимость кредитования по-прежнему высока для субъектов малого предпринимательства.',
    TEXT_2:
      'В большинстве банков ** программы кредитования малого бизнеса подразумевают наличие залога, а беззалоговые кредиты ограничены определенной суммой, оформляются под высокий процент и требуют поручительства.  К тому же управление бизнесом - это образ жизни, который требует постоянного присутствия и включенности во все процессы. Отсутствие свободного времени - еще одна из основных причин, почему владельцы малого бизнеса не спешат кредитоваться в банке.',
    BENEFITS: {
      HEADING: 'В чем преимущества онлайн-кредитования на сайте Salem.kz для владельцев малого бизнеса?',
      SPEED: {
        TITLE: 'Скорость.',
        DESC:
          'Простое оформление заявки на сайте и ускоренная система оценки платежеспособности клиентов позволяют получить необходимые средства максимально быстро. На сайте Salem.kz взять микрокредит можно без справок, поручительства, залога и длительного ожидания решения банка. Деньги отправят на банковскую карту в течение 15 минут после одобрения заявки.'
      },
      REMOTE: {
        TITLE: 'Дистанционность.',
        DESC:
          'Для оформления и получения кредита не требуется посещение офиса, что очень удобно, особенно для владельцев садоводческих и овощеводческих хозяйств, у которых каждая минута на счету и совершенно нет времени, чтобы тратить его в очереди. Заявка оформляется на сайте компании, и после ее одобрения деньги сразу поступают на карточку клиента.'
      },
      TRUST: {
        TITLE: 'Доверие',
        DESC:
          'Чтобы получить кредит в банке, необходимо предоставить ликвидный залог (новый автотранспорт, недвижимость и пр.) или заручиться поддержкой поручителей. Оформление микрокредитов на сайте Salem.kz не требует залога и поручительства.'
      },
      CREDIT_HISTORY: {
        TITLE: 'Улучшение кредитной истории',
        DESC:
          'Некоторые предприниматели подумывают о крупном потребительском кредите, но сомневаются, что в банке одобрят его получение. Оформляя микрокредит онлайн, а главное - своевременно его погашая, можно не только получить средства на ближайшие цели, но и улучшить кредитную историю, в результате став более желанным клиентом для банков, и шансы получить крупный потребительский кредит значительно увеличатся.'
      },
      CONFIDENTIALITY: {
        TITLE: 'Конфиденциальность',
        DESC:
          'Беря в долг деньги у знакомых или родных, придется объяснять, зачем они понадобились, почему нет «подушки безопасности», и выслушивать советы о пользе планирования. Заполняя заявку на сайте Salem.kz, вы указываете необходимую сумму, никому ничего не объясняя и не оправдываясь.'
      },
      FINANCIAL_DISCIPLINE: {
        TITLE: 'Развитие финансовой дисциплины.',
        DESC:
          'При оформлении микрокредита на сайте Salem.kz вам будет предоставлен график погашения, которому необходимо следовать неукоснительно и к которому вы со временем привыкнете. Погасив микрокредит, вы сможете безболезненно откладывать ту же самую сумму для дальнейшего развития бизнеса или для других целей.'
      }
    }
  },
  HOW_TO_RETURN: {
    TITLE: 'Как возвращать',
    NOTE:
      'При своевременном погашении микрокредита Вы улучшаете свою кредитную историю и увеличиваете шансы повысить свой кредитный лимит. Вы можете произвести погашение одним из следующих способов оплаты:',
    METHODS: {
      ONLINE_BANKING: 'онлайн-перевод (на счет юридического лица в мобильном приложении банка)',
      KASSA_24_TERMINAL: 'через платежные терминалы Kassa24',
      CARD_PAYMENT: 'онлайн-оплата (оплата с личного кабинета)',
      QIWI_TERMINAL: 'через платежные терминалы Qiwi',
      QIWI_WALLET: 'онлайн-оплата с помощью Qiwi-кошелька',
      PAYMENT_BANK_DESK: 'через кассу любого банка'
    },
    DIFFERENT_PAYMENT_TYPE: 'Выберите другой способ оплаты',
    END_NOTE:
      '<b>Salem.kz активно работает над улучшением сервиса, поэтому в ближайшее время будут добавлены дополнительные способы оплаты!</b>'
  },
  RETURN_BY_ONLINE_BANKING: {
    TITLE: 'Онлайн-перевод',
    NOTE:
      'Погашение микрокредита можно произвести через мобильное приложение банка, которое позволяет осуществлять онлайн-переводы на счета юридических лиц. В зависимости от банка и его мобильного приложения порядок оплаты может незначительно изменяться.',
    OPTION_TITLE:
      '<b><i>Ниже размещена пошаговая инструкция как произвести онлайн-перевод через мобильное приложение Халык банка.</i><b>',
    STEPS: {
      STEP1: 'В мобильном приложениии Halyk Bank необходимо пройти по вкладке "Переводы" - "Все переводы"',
      STEP2: 'Выбрать "<b>На номер счета</b> юридических лиц"',
      STEP3: 'Внести номер счета Salem (KZ226018771000900151), БИН 200540006761, выбрать резидент и нажать "Далее"',
      STEP4:
        'При корректном вводе БИН должно отразиться полное наименование Компании. В поле "КБе (код получателя)" необходимо выбрать "5 - другие финансовые организации", в поле "КНП (код назначения платежа)" выбрать "421 - погашение краткосрочных займов" (до 12 месяцев) либо "423 - погашение долгосрочных займов" (свыше 12 месяцев). ВАЖНО! В поле назначение перевода необходимо указать номер договора и ИИН/ФИО. Указать согласие с условиями перевода и нажать "Далее".   ',
      STEP5: 'В поле "Откуда" выбрать свою карту, в поле "Сколько" указать сумму к погашению и нажать "Далее"',
      STEP6:
        'На последнем шаге оплаты нажать "Подтвердить" и оплата будет успешно произведена при наличии достаточных средств на карте'
    }
  },
  RETURN_BY_KASSA_24_TERMINAL: {
    TITLE: 'Терминалы КАССА 24',
    STEPS: {
      STEP1: 'Найдите ближайший Терминал <b>"Kassa 24"</b>',
      STEP2: 'Затем выберите раздел <b>"Финансовые организации"</b>',
      STEP3: 'В подразделе <b>"Выберите сервис"</b>, выберите <b>"МКО И МФО"</b>',
      STEP4: 'Далее найдите <b>"Salem.kz"</b>',
      STEP5: 'Введите ваш ИИН и нажмите <b>"Продолжить"</b>',
      STEP6:
        'Внесите необходимую сумму в терминал и нажмите <b>"Оплатить"</b>. <b>ВАЖНО!</b> При оплате удерживается комиссия в размере <b>1.3%</b>.'
    }
  },
  ONLINE_SIGNATURE: {
    AGREEMENT: 'Договор',
    DOCUMENT_OPEN: 'Открыть',
    DOCUMENT_DOWNLOAD: 'Cкачать'
  },
  RETURN_BY_CARD_PAYMENT: {
    TITLE: 'Онлайн-платёж картой ',
    STEPS: {
      STEP1: 'Необходимо зайти в личный кабинет',
      STEP2: 'Открыть раздел <b>"Мои кредиты"</b>, после необходимо добавить карту',
      STEP3: 'После добавления карты нужно указать сумму к оплате и нажать на кнопку оплатить ',
      STEP4: 'После необходимо подтвердить оплату',
      STEP5:
        'Если оплата прошла успешно, выйдет уведомление, что оплата прошла успешно. <b>ВАЖНО!</b> Комиссия составляет <b>3.7%</b>'
    }
  },
  RETURN_BY_QIWI_TERMINAL: {
    TITLE: 'Терминалы QIWI',
    STEPS: {
      STEP1: 'Найдите ближайший терминал QIWI. В терминале необходимо зайти в раздел <b>"Оплата услуг"</b>',
      STEP2: 'Выбрать <b>"Погашение кредитов/Фин.услуги"</b>',
      STEP3: 'Выбрать раздел <b>"МКО И МФО"</b>',
      STEP4: 'Найти и нажать кнопку <b>"Salem.kz"</b>',
      STEP5: 'В поле ввести <b>свой ИИН</b> и нажать <b>"Вперед"</b>',
      STEP6:
        'Внести в терминал нужную Вам сумму денег и нажать <b>"Вперед"</b>. ВАЖНО! В сумме к оплате по умолчанию отражается сумма платежа текущего платежного периода, а также сумма просроченной задолженности (при наличии таковой).',
      STEP7: 'На последнем шаге оплаты нажать <b>"Оплатить"</b>'
    }
  },
  RETURN_BY_QIWI_WALLET: {
    TITLE: 'QIWI-кошелек',
    STEPS: {
      STEP1: 'Открыть QIWI-кошелек',
      STEP2: 'В строке <b>"Поиск"</b> ввести Salem.kz и выбрать из выпадающего списка',
      STEP3: 'Указать ИИН и сумму к оплате в соответствующих полях',
      STEP4: 'Нажать <b>"Оплатить" (комиссия 2%)</b>'
    }
  },
  RETURN_BY_PAYMENT_BANK_DESK: {
    TITLE: 'Оплата через кассу банка',
    NOTE:
      'Погашение микрокредита можно произвести через кассу любого банка, которое осуществляет прием и перевод денежных средств на счета юридических лиц. Для погашения через кассу Вам необходимо лишь передать кассиру свои данные (ИИН, ФИО) и данные Salem.kz:',
    STEPS: {
      STEP1: 'Банковский счет Salem.kz <b>№KZ226018771000900151</b> в АО "Народный Банк Казахстана"',
      STEP2: 'БИН Salem.kz <b>200540006761</b>',
      STEP3:
        'Кбе (код получателя) <b>"5"</b> или <b>"7"</b>, КНП (код назначения платежа) - <b>"421"</b> (по микрокредитам со сроком не более 12 месяцев) или <b>"423"</b> (по микрокредитам со сроком более 12 месяцев)',
      STEP4:
        'Свой <b>номер договора микрокредита</b>. ВАЖНО! Попросите кассира указать в назначении платежа свой номер договора микрокредита, по которому производите оплату.  '
    }
  },
  CONTACTS: {
    TITLE: 'Контакты',
    SECTION_MAIN: {
      TITLE: 'Служба заботы о клиентах',
      PHONE: phoneNumber,
      EMAIL: 'info@salem.kz',
      ADDRESS: '050012, Республика Казахстан, г. Алматы, Алмалинский район, проспект Сейфуллина, дом №502, 8 этаж'
    },
    SECTION_WORK_HOURS: {
      TITLE: 'Часы работы:',
      WORK_DAYS: 'Понедельник-Воскресенье',
      WORK_HOURS: 'с 9:00 до 18:00'
    }
  },
  FORM: {
    FIELD_SLIDER_AMOUNT_LABEL: 'Сумма микрокредита',

    FIELD_SLIDER_TERM_LABEL: 'Срок микрокредита',
    FIELD_SLIDER_TERM_MONTH_SUFFIX: 'мес.',
    FIELD_SLIDER_TERM_TOOLTIP: 'Срок микрокредита зависит от суммы микрокредита',

    FIELD_PRODUCT_INSURANCE_LABEL: 'Страхование заемщика',
    FIELD_PRODUCT_INSURANCE_INFO:
      'от 4,8% годовых по микрокредиту + онлайн-страхование заемщика от несчастного случая на весь срок действия микрокредита от АО "КСЖ "Freedom Finance Life". Стоимость страховки {insuranceCost}, срок действия – до {validity} г., страховое покрытие - {totalPrincipal}. Декларация по онлайн-страхованию заемщика от несчастного случая <a href="/docs/life-declaration.docx" download="ДЕКЛАРАЦИИ ПО ОНЛАЙН-СТРАХОВАНИЮ ЗАЁМЩИКА ОТ НЕСЧАСТНОГО СЛУЧАЯ.docx"><i class="el-icon-download"></i></a>. Я ознакомлен и согласен с договором добровольного онлайн-страхования заемщика от несчастных случаев и прошу ТОО МФО «Salem Credit» внести за меня стоимость страховки в счет предоставляемого микрокредита.',
    FIELD_PRODUCT_REGULAR_LABEL: 'Без страхования',
    FIELD_PRODUCT_REGULAR_INFO:
      '54,4% годовых по микрокредиту, страхование заемщика недоступно, вероятность одобрения ниже.',

    [FORM_ERRORS.FIELD_ERROR_DEFAULT]: 'Кажется, что-то пошло не так. Попробуйте позже, пожалуйста',
    [FORM_ERRORS.FIELD_ERROR_AMOUNT_LIMIT]: 'Максимальная доступная сумма для новых клиентов 1 000 000 тенге',
    [FORM_ERRORS.FIELD_ERROR_AVAILABLE_CREDIT]:
      'Запрашиваемая вами сумма {loanAmount} превышает ваш доступный лимит {availableCredit}',
    [FORM_ERRORS.FIELD_ERROR_MAX_PAYMENT]:
      'Запрашиваемая вами сумма ежемесячного платежа {paymentAmount} превышает ваш доступный лимит {maxPaymentAmount}',
    [FORM_ERRORS.FIELD_ERROR_NON_FINAL_APPLICATION_STATE]: 'У вас есть незаконченные заявки',
    [FORM_ERRORS.FIELD_ERROR_NON_FINAL_AGREEMENT_STATE]: 'У вас есть невыданный займ',
    [FORM_ERRORS.FIELD_ERROR_REQUIRED]: 'Это поле является обязательным к заполнению',
    [FORM_ERRORS.FIELD_ERROR_ONLY_NUMBERS]: 'Это поле принимает только числа',
    [FORM_ERRORS.FIELD_ERROR_EMAIL_REGEX]: 'Неверный формат электронной почты',
    [FORM_ERRORS.FIELD_ERROR_PHONE_NUMBER_FORMAT]: 'Неверный формат номера телефона',
    [FORM_ERRORS.FIELD_ERROR_ID_NUMBER_FORMAT]: 'Введите ИИН (12 цифр из удостоверения личности)',
    [FORM_ERRORS.FIELD_ERROR_ID_NUMBER_NOT_EXIST]: 'Введен неправильный ИИН или пароль. Пожалуйста, попробуйте еще раз',
    [FORM_ERRORS.FIELD_ERROR_CLIENT_IS_BLOCKED]: 'Превышен лимит на запрос пароля, {helpLink}',
    [FORM_ERRORS.HELP_LINK_TEXT]: 'напишите нам',
    [FORM_ERRORS.FIELD_ERROR_PASSWORD_FORMAT]: 'Минимум 8 символов',
    [FORM_ERRORS.FIELD_ERROR_PASSPORT_ID]:
      'Неверная длина номера удостоверения личности. Номер должен состоять из 9 цифр',
    [FORM_ERRORS.FIELD_ERROR_DATE_FORMAT]: 'Пожалуйста, используйте правильный формат даты: ДД/ММ/ГГГГ',
    [FORM_ERRORS.FIELD_ERROR_DATE]: 'Неверная дата',
    [FORM_ERRORS.FIELD_ERROR_DATE_IS_PAST]: 'Дата не может быть в прошлом',
    [FORM_ERRORS.FIELD_ERROR_DATE_IS_FUTURE]: 'Дата не может быть в будущем',
    [FORM_ERRORS.FIELD_ERROR_IS_CLIENT_PHONE_NUMBER]: 'Телефон уже указан как основной',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MIN_FOUR]: 'Сумма расходов  должна состоять из 4 и более цифр',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MIN_FIVE]: 'Сумма дохода должна состоять из 5 и более цифр',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MAX_ADDITIONAL_INCOME]: 'Максимальная сумма 10 000 000 тенге',
    [FORM_ERRORS.FIELD_ERROR_BANK_ACCOUNT_NUMBER_FORMAT]:
      'Неверный IBAN-счёт. IBAN – это номер банковского счета в формате KZХХ ХХХХ ХХХХ ХХХХ ХХХХ. Вы можете уточнить его обратившись в свой банк, а так же в интернет банкинге.',
    [FORM_ERRORS.FIELD_ERROR_BANK_CARD_NOT_VERIFIED]: 'Банковская карта не прошла проверку',
    [FORM_ERRORS.FIELD_ERROR_BANK_CARD_VERIFICATION_EXPIRED]:
      'Проверка банковской карты устарела, требуется пройти заново',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_400]: 'Банковская карта уже прошла проверку',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_409]: 'Проверка суммы прошла неудачно',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_410]: 'Время на проверку истекло',

    [FORM_ERRORS.FIELD_ERROR_WRONG_AGE]: 'Извините, мы выдаем микрокредит клиентам от 18 до 68 лет',
    [FORM_ERRORS.FIELD_ERROR_NO_LETTER]: 'Укажите название улицы',
    [FORM_ERRORS.FIELD_ERROR_USERNAME_NOT_UNIQUE]: 'Данный ИИН уже зарегистрирован на salem.kz {login}',
    [FORM_ERRORS.FIELD_ERROR_EMAIL_NOT_UNIQUE]: 'Этот email уже зарегистрирован на salem.kz {login}',
    [FORM_ERRORS.FIELD_ERROR_PHONE_NOT_UNIQUE]: 'Этот номер телефона уже зарегистрирован на salem.kz {login}',
    [FORM_ERRORS.FIELD_ERROR_WRONG_VERIFICATION_CODE]: 'Введен неверный код',
    [FORM_ERRORS.FIELD_ERROR_VERIFICATION_RESTORE_FAILED]: 'Не удалось восстановить проверку',
    [FORM_ERRORS.FIELD_ERROR_VERIFICATION_CREATE]: 'Не удалось начать проверку',
    [FORM_ERRORS.FIELD_ERROR_INVALID_IIN]: 'Неверно указан IIN',
    [FORM_ERRORS.FIELD_ERROR_INVALID_IBAN]: 'Данный номер банковского счета (IBAN) является недействительным',
    [FORM_ERRORS.FIELD_ERROR_NEW_PASSWORD_SAME_AS_OLD]: 'Новый пароль не может совпадать с текущим',
    [FORM_ERRORS.FIELD_ERROR_PASSWORD_IS_NOT_SAME]: 'Пароль должен совпадать',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CURRENT_PASSWORD]: 'Неверный текущий пароль',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_EMAIL]: 'Неверный E-mail',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_PHONE]: 'Неверный номер телефона',
    [FORM_ERRORS.FIELD_ERROR_INVALID_PHONE_NUMBER]: 'Неверный номер телефона',
    [FORM_ERRORS.FIELD_ERROR_SMALL_LENGTH]: 'Текст слишком короткий. Напишите подробнее.',
    [FORM_ERRORS.FIELD_ERROR_BIG_LENGTH]: 'Текст слишком длинный.',
    [FORM_ERRORS.COID_VERIFICATION_FAILED]: 'Проверка прошла неудачно',
    [FORM_ERRORS.BANK_STATEMENT_END_DATE_BEFORE_START_DATE]: 'Дата окончания не может быть раньше даты начала',
    [FORM_ERRORS.BANK_STATEMENT_FILES_REQUIRED]: 'Необходимо добавить выписку',
    [FORM_ERRORS.BANK_STATEMENT_LIMIT_REACHED]: 'Достигнут лимит количества файлов',
    [FORM_ERRORS.BANK_STATEMENT_UPLOAD_ERROR]: 'Необходимо добавить выписку',
    [FORM_ERRORS.FIELD_ERROR_FILE_TYPE]: 'Недопустимый формат. Разрешённые форматы jpg, png, jpeg, pdf',
    [FORM_ERRORS.BANK_STATEMENT_FILE_SIZE]:
      'Размер исходного изображения не соответствует необходимому формату, загрузите пожалуйста файл размером не более 20 МБ',

    ERROR_NOTIFICATION_TITLE: 'Ошибка!',
    ERROR_APPLICATION_REJECTED_429:
      'На данный момент мы не можем предоставить вам микрокредит. Вы можете подать заявку после {dateTime}, или на www.koke.kz.',

    SUBMIT_BUTTON: 'Следующий шаг',
    CONFIRM_BUTTON: 'Подтвердить',
    SUBMIT_APPLICATION: 'Подать заявку',
    DECLINE_BUTTON: 'Отказаться',
    SUBMIT_VERIFICATION: 'Подтвердить',
    UPLOAD_FILES: 'Загрузить файлы',
    SAVE: 'Сохранить',

    FIELDS: {
      username: {
        TITLE: 'Ваш ИИН',
        TOOLTIP: 'Введите ИИН (12 цифр из удостоверения личности)'
      },
      email: {
        TITLE: 'Ваш email',
        TOOLTIP:
          'Введите ваш email. На указанный адрес будут отправлены пароль для входа в личный кабинет, документы и уведомления по микрокредиту'
      },
      mobilePhone: {
        TITLE: 'Номер телефона',
        TOOLTIP:
          'Укажите номер мобильного телефона - он будет использоваться для входа в личный кабинет и связи с вами.'
      },
      contactPhone: {
        TITLE: 'Номер телефона',
        TOOLTIP: 'Укажите номер телефона, по которому можно связаться по вопросам обращения.'
      },
      appealText: {
        TITLE: 'Текст сообщения',
        TOOLTIP: 'Укажите причину возникновения просрочки'
      },
      code: {
        TITLE: 'SMS-код',
        TOOLTIP: 'Введите 6-х значный код, отправленный на Ваш мобильный телефон.'
      },
      newEmail: {
        TITLE: 'Новый email'
      },
      identifierEmail: {
        TITLE: 'Адрес эл.почты'
      },
      identifierPhone: {
        TITLE: 'Номер телефона'
      },
      identifier: {
        TITLE: 'Телефон или E-mail'
      },
      passwordResetCode: {
        TITLE: 'Код подтверждения'
      },
      emailCode: {
        TITLE: 'Код',
        TOOLTIP: 'Введите 6-х значный код, отправленный на указанный email адрес.'
      },
      password: {
        TITLE: 'Ваш пароль'
      },
      oldPassword: {
        TITLE: 'Старый пароль'
      },
      newPassword: {
        TITLE: 'Новый пароль'
      },
      repeatNewPassword: {
        TITLE: 'Повторите пароль'
      },
      testAmount: {
        TITLE: 'Сумма из выписки',
        TOOLTIP: 'Проверьте выписку счёта указанной банковской карты и введите сумму'
      },

      personalInfo: {
        name: {
          firstName: {
            TITLE: 'Имя',
            TOOLTIP: 'Введите имя'
          },
          lastName: {
            TITLE: 'Фамилия',
            TOOLTIP: 'Введите фамилию'
          },
          middleName: {
            TITLE: 'Отчество',
            TOOLTIP: 'Введите отчество'
          }
        },
        birthDate: {
          TITLE: 'Дата рождения',
          PLACEHOLDER: 'ДД / ММ / ГГГГ',
          TOOLTIP: 'Введите ваш день рождения'
        },
        gender: {
          TITLE: 'Пол',
          TOOLTIP: 'Укажите ваш пол'
        }
      },
      identityDocuments: {
        number: {
          TITLE: 'Номер удостоверения личности',
          TOOLTIP: 'Введите номер вашего удостоверения личности'
        },
        issuedAt: {
          TITLE: 'Дата выдачи',
          PLACEHOLDER: 'ДД / ММ / ГГГГ',
          TOOLTIP: 'Введите дату выдачи'
        },
        expiresAt: {
          TITLE: 'Срок действия',
          PLACEHOLDER: 'ДД / ММ / ГГГГ',
          TOOLTIP: 'Введите срок действия'
        },
        issuedBy: {
          TITLE: 'Орган выдачи',
          TOOLTIP: 'Введите орган выдачи'
        }
      },
      declared: {
        fields: {
          region: { TITLE: 'Область', TOOLTIP: 'Выберите область проживания' },
          house: { TITLE: 'Номер дома', TOOLTIP: 'Укажите номер дома' },
          apartment: {
            TITLE: 'Номер квартиры',
            TOOLTIP: 'Укажите номер квартиры. Если вы проживаете в частном доме, оставьте поле пустым.'
          }
        },
        city: {
          TITLE: 'Город/населенный пункт',
          TOOLTIP: 'Выберите город или населенный пункт'
        },
        street: { TITLE: 'Улица', TOOLTIP: 'Укажите название улицы' }
      },
      living: {
        fields: {
          region: { TITLE: 'Область', TOOLTIP: 'Выберите область проживания' },
          house: { TITLE: 'Номер дома', TOOLTIP: 'Укажите номер дома' },
          apartment: {
            TITLE: 'Номер квартиры',
            TOOLTIP: 'Укажите номер квартиры. Если вы проживаете в частном доме, оставьте поле пустым.'
          }
        },
        city: {
          TITLE: 'Город/населенный пункт',
          TOOLTIP: 'Выберите город или населенный пункт'
        },
        street: { TITLE: 'Улица', TOOLTIP: 'Укажите название улицы' }
      },
      matchesDeclaredAddress: {
        TITLE: 'Мой фактический адрес проживания совпадает с пропиской'
      },
      data: {
        [PROFILE_ADDITIONAL_INFO_KEYS.EDUCATION]: {
          stage: {
            TITLE: 'Образование',
            TOOLTIP: 'Пожалуйста, укажите степень образования'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO]: {
          employmentStatus: {
            TITLE: 'Занятость',
            TOOLTIP: 'Пожалуйста, укажите занятость'
          },
          companyName: {
            TITLE: 'Название компании',
            TOOLTIP: 'Введите наименование Компании'
          },
          industry: {
            TITLE: 'Вид деятельности',
            TOOLTIP: 'Если в списке не указана Ваша сфера деятельности, то укажите "Другое"'
          },
          position: {
            TITLE: 'Должность',
            TOOLTIP: 'Пожалуйста, укажите свою занимаемую должность'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.BANK_STATEMENT]: {
          startDate: {
            TITLE: 'Дата начала выписки',
            PLACEHOLDER: 'ДД / ММ / ГГГГ',
            TOOLTIP: 'Введите дату начала выписки'
          },
          endDate: {
            TITLE: 'Дата окончания выписки',
            PLACEHOLDER: 'ДД / ММ / ГГГГ',
            TOOLTIP: 'Введите дату окончания выписки'
          },
          turnover: {
            TITLE: 'Общая сумма оборотов',
            TOOLTIP: 'Введите общую сумму оборотов'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.FAMILY]: {
          maritalStatus: {
            TITLE: 'Семейное положение',
            TOOLTIP: 'Пожалуйста, укажите ваше семейное положение'
          },
          numberOfChildren: {
            TITLE: 'Количество детей (до 18 лет)',
            TOOLTIP: 'Пожалуйста, укажите количество детей младше 18 лет'
          },
          additionalContactName: {
            TITLE: 'Дополнительный контактный номер',
            TOOLTIP:
              'Пожалуйста, предоставьте номер дополнительного контактного лица (родственник, друг, коллега и т.д.)'
          }
        }
      },
      purpose: {
        TITLE: 'Цель микрокредита',
        TOOLTIP: 'Цель микрокредита'
      },
      input: {
        metadata: {
          paymentDate: {
            TITLE: 'День оплаты'
          }
        }
      },
      disbursement: {
        type: {
          TITLE: 'Выберете способ перечисления:',
          TOOLTIP: 'Выберите способ перечисления'
        },
        data: {
          bankAccountNumber: {
            TITLE: 'Банковский счёт (IBAN)',
            TOOLTIP:
              'IBAN – это номер банковского счета в формате KZХХ ХХХХ ХХХХ ХХХХ ХХХХ. Вы можете уточнить его обратившись в свой банк, а так же в интернет банкинге.'
          },
          bankCardNumber: {
            TITLE: 'Банковская карта (Visa, Master...)',

            ATTACH: {
              LABEL: 'Нажмите, чтобы начать',
              BUTTON: 'Прикрепить карту',
              HELPER:
                'На вашей карте заблокирована случайная **сумма** (до 100 тенге). Размер заблокированной **суммы** является кодом, который необходимо **ввести** для **подтверждения карты**.',
              FEE: '* Комиссия за зачисление на карту 1,25%'
            },
            VERIFY: {
              LABEL: 'Необходимо подтвердить данные карты',
              BUTTON: 'Подтвердить'
            },
            REMOVE: {
              LABEL: 'Номер вашей карты на проверке',
              BUTTON: 'Отменить',
              CONFIRM_TITLE: 'Отменить проверку карты',
              CONFIRM_TEXT: 'Вы уверены, что хотите отменить проверку этой карты?',
              CONFIRM_SUBMIT: 'Подтверждаю',
              CONFIRM_CANCEL: 'Оставить'
            },
            CANCEL: {
              BUTTON: 'Отменить'
            },
            CONFIRMED: {
              LABEL: 'Ваша карта прошла проверку',
              BUTTON: 'Удалить'
            },
            EXPIRED: {
              LABEL: 'Время на проверку истекло',
              BUTTON: 'Начать заново'
            }
          }
        }
      },
      signingAgreement: {
        type: {
          TITLE: 'Способ подписания документов:',
          TOOLTIP: 'Выберите способ подписания документов'
        }
      },
      selectCard: {
        TITLE: 'Выберите карту для оплаты'
      }
    },

    OPTIONS: {
      gender: {
        [GENDER.MALE]: 'мужчина',
        [GENDER.FEMALE]: 'женщина'
      },
      disbursementType: {
        [DISBURSEMENT_TYPE.BANK_ACCOUNT]: 'Банковский счёт (IBAN)',
        [DISBURSEMENT_TYPE.BANK_CARD]: 'Банковская карта (Visa, Master...)'
      }
    },

    SECTIONS: {
      'personal-data': 'Персональные данные',
      address: 'Адрес регистрации',
      finances: 'Работа',
      'additional-info': 'Дополнительная информация'
    }
  },
  SCHEDULE: {
    PAYMENT_MONTHLY: 'Ежемесячный платёж',
    PAYMENT_GRAPHIC: 'График платежей',
    PAYMENT_GRAPHIC_DATE: 'Дата оплаты',
    PAYMENT_GRAPHIC_PRINCIPAL: 'основная сумма',
    PAYMENT_GRAPHIC_INTEREST: 'Проценты',
    PAYMENT_GRAPHIC_AMOUNT: 'Сумма оплаты',
    SUBMIT_VERIFICATION: 'Подтвердить'
  },
  DOCUMENTS: {
    REGISTRATION_FEE_NOTICE: 'Уведомление об учетной регистрации от 07.08.2020',
    PERSONAL_DATA_COLLECT_CONSENT: 'Согласие на сбор и обработку персональных данных',
    PERSONAL_DATA_PROCESS_CONSENT:
      'Политика в отношении обработки персональных данных ТОО «Микрофинансовая организация «Салем Кредит»',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_15_08_2022:
      'Правила добровольного онлайн страхования от несчастного случая, СД от 05.03.2021 (с изменениями на 15.04.2024)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_19_08_2024:
      'Правила добровольного онлайн страхования жизни, СД от 15.08.2022 (с изменениями на 19.08.2024)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE_15_08_2022:
      'Правила добровольного онлайн страхования жизни, СД от 15.08.2022 (с изменениями на 15.04.2024)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE_19_08_2024:
      'Правила добровольного онлайн страхования заемщика от НС от 05.03.2021 (с изменениями на 19.08.2024)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_LAST:
      'Правила добровольного онлайн страхования заемщика от НС от 05.03.2021 (с изменениями на 02.05.2023)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE:
      'Правила добровольного онлайн страхования заемщика от НС от 05.03.2021 (с изменениями на 01.09.2022)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE:
      'Правила добровольного онлайн страхования жизни заемщика от 27.07.2020 (с изменениями на 12.10.2020)',
    RULES_MICROCREDIT: 'Правила предоставления микрокредитов 01.09.2022',
    MICROFINANCE_LICENSE: 'Лицензия на осуществление микрофинансовой деятельности от 31.03.2021'
  },
  REPORTS: {
    FINANCIAL_REPORT_2021: 'Финансовая отчетность ТОО «Микрофинансовая организация «Салем Кредит» за 2021 год'
  },
  PAYMENT_CARD: {
    CURRENT: 'Карта оплаты',
    ADD: 'Добавить новую карту оплаты',

    BUTTON: {
      PAY: 'Оплатить',
      SELECT: 'Выбрать',
      ADD: 'Добавить',
      ACCEPT: 'Сохранить'
    },

    APPROVE: {
      TITLE: 'Необходимо подтвердить данные карты'
    },

    CONFIRMED: {
      TITLE: 'Банковская карта успешно прошла проверку'
    },

    ACCEPT: {
      SUCCESS: 'Банковская карта успешно сохранена'
    }
  },
  MULTI_UPLOAD_INPUT: {
    PLACEHOLDER: '+ Добавить документ',
    TOOLTIP: 'Добавьте Вашу выписку'
  }
};
